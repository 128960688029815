import React from 'react';
import logo from './assets/img/pso-logo.svg';
import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookSquare, faTwitterSquare } from '@fortawesome/free-brands-svg-icons'

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className="App-logo-container">
          <img src={logo} className="App-logo" alt="logo" />
        </div>
        <p>
          New website coming soon...
        </p>
        <div className="App-video-frame">
        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/eL2THtJ3G48?rel=0" frameBorder="0"
                gesture="media" allow="encrypted-media" allowFullScreen></iframe>
        </div>
        <p>
          If you would like to get in touch you can <a
            className="App-link"
            href="mailto:rachel-cotton@hotmail.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            email us
          </a> and keep up to date with our projects on our social media pages:

        </p>
        <ul className="App-link-list">
          <li><a
              className="App-link-logo"
              href="https://www.facebook.com/PiccadillySO"
              target="_blank"
              rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faFacebookSquare} /> Facebook /PiccadillySO</a>
          </li>
          <li>
            <a
                className="App-link-logo"
                href="https://twitter.com/PiccadillySO"
                target="_blank"
                rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faTwitterSquare} /> Twitter @PiccadillySO</a>
          </li>
        </ul>

      </header>
    </div>
  );
}

export default App;
